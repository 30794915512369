export class Collapsed {
    private menu: HTMLDivElement; 
    private btn: HTMLDivElement;
    
    constructor(public buttonId: string) {
        this.btn = document.querySelector(this.buttonId)!;    
        this.menu = document.querySelector('#collapsed')!; 
        console.log(this.btn, this.menu)
        this.btn.addEventListener('click',this.toggleMenu.bind(this));
    }

    toggleMenu() {
        if (this.menu) { 
            this.menu.classList.toggle('showMenu');
            this.menu.classList.toggle('closeMenu');
            this.btn.classList.toggle('cross');
        }
    }
}